@import './globals/index.scss';

* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: none;
  font: inherit;
}

h1, h2, h3, h4, h5, h6, p  {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: none;
  font: inherit;
}

*::after, *::before {
  box-sizing: inherit;
}

*:focus-visible {
  outline: none;
}

html {
  box-sizing: border-box;
}

body {
  color: $gmb-color-default !important;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main, form legend {
  display: block;
}

ol, ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

blockquote, q {
  quotes: none;
}

.detailNews blockquote, .detailHowto blockquote, .detailPage blockquote, .templateOne blockquote {
  border-bottom: 0;
  border-top: 0;
}

button, select, textarea, input {
  color: inherit;
  line-height: inherit;
  background-color: transparent;
  border-radius: 0;
}

button, select, textarea,
input[type="button"], input[type="email"], input[type="number"],
input[type="password"], input[type="search"], input[type="submit"],
input[type="tel"], input[type="text"], input[type="url"] {
  appearance: none;
}

textarea {
  resize: vertical;
  overflow: auto;
}

select::-ms-expand {
  display: none; // hide Select default icon on IE
}

input::-ms-clear {
  display: none; // hide X icon in IE and Edge
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, video, svg {
  max-width: 100%;
}

a {
  text-decoration: none;
}

label {
 margin-bottom: 0;
 font-weight: initial !important;
}

.payments-spinner-text {
  color: $gmb-color-accent;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

/* Track */
 ::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
 ::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:#c4c4c4;
}

.bg-title {
  display: none;
}

.row.first-row:after{
    clear: none;
}

.nav-tabs > li > a {
  &:hover, &:focus {
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

.ui-messages-info-detail, .ui-messages-warn-detail, .ui-messages-error-detail, .ui-messages-fatal-detail,.ui-message-info-detail, .ui-message-warn-detail, .ui-message-error-detail, .ui-message-fatal-detail{
	margin-left:0px !important;
	}

.ui-messages-info-summary, .ui-messages-warn-summary, .ui-messages-error-summary, .ui-messages-fatal-summary, .ui-message-info-summary, .ui-message-warn-summary, .ui-message-error-summary, .ui-message-fatal-summary{
	margin-left:0px !important;
	font-weight:bold;
	}
